<template>
  <Main>
    <div v-if="accountData && !isLoading" class="account-public">
      <sdPageHeader>
        <template v-slot:title>
          <div class="account__wrapper">
            <div class="df aic fww">
              <p class="account__title">
                {{ accountData.attributes.title ?? accountData.attributes.trade.name }}
                {{ accountData.attributes.code ? `(${accountData.attributes.code})` : '' }}
              </p>
              <Status
                :status="accountData.value?.attributes.status"
                style="margin-left: 20px"
                v-if="isClosed && accountData.value?.attributes.status"
              />
              <Status
                :text="i18n.t(`statuses.trading_account.${accountData.attributes.status}`)"
                :status="accountData.attributes.status"
                style="margin-left: 20px"
                v-else-if="accountData.attributes.status"
              />
              <Status
                :text="i18n.t('accounts.public.self_invested')"
                status="active"
                v-if="accountData.attributes.self_invested"
              />
              <Status
                :text="i18n.t('accounts.public.self_account')"
                status="leverage"
                v-else-if="accountData.attributes.owner"
              />
              <Status
                :text="i18n.t(`publish.modal.${accountData.attributes.system_type}`)"
                status=""
                v-if="accountData.attributes.system_type"
              />
              <Status status="promo" v-if="accountData.attributes.promo" />
              <p class="account__subtitle title" v-if="accountData?.attributes?.amount && !isPc">
                <span>
                  {{ `${i18n.t('accounts.amount')}` }}
                  <br />
                </span>
                {{ i18n.n(+accountData.attributes.amount, 'decimal') }}
                <span>{{ accountData.attributes.amount_currency }}</span>
              </p>
            </div>
          </div>
        </template>
        <template v-slot:buttons>
          <div class="account-public__buttons aic">
            <p class="account__subtitle" v-if="accountData?.attributes?.amount && isPc">
              <span>
                {{ `${i18n.t('accounts.amount')}` }}
                <br />
              </span>
              {{ i18n.n(+accountData.attributes.amount, 'decimal') }}
              <span>{{ accountData.attributes.amount_currency }}</span>
            </p>
            <PublicAccountButtons
              :isInvestor="isInvestor"
              :account="accountData.attributes"
              :isClosed="isClosed"
              @openModal="openModal"
            />
          </div>
        </template>
      </sdPageHeader>

      <GraphComponent />
      <a-row :gutter="20" v-if="accountData.attributes.promo">
        <a-col :md="24">
          <sdPageHeader :title="i18n.t('accounts.invest.promo_title')" />
        </a-col>
        <a-col :md="12">
          <sdCards headless>
            <div class="promo-info">
              <div class="promo-info__inner">
                <p class="promo-info__title">{{ i18n.t('accounts.invest.promo_days_left') }}:</p>
                <p class="promo-info__text">{{ accountData.attributes.promo.days_left }}</p>
              </div>
              <div class="promo-info__inner">
                <p class="promo-info__title">{{ i18n.t('accounts.invest.promo_end') }}:</p>
                <p class="promo-info__text">{{ i18n.d(accountData.attributes.promo.end_date) }}</p>
              </div>
            </div>
          </sdCards>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :md="12" v-if="accountData.attributes.description">
          <sdPageHeader :title="i18n.t('publish.modal.input_description')" />
        </a-col>
        <a-col :md="isInvestor ? 24 : 12" v-if="isPc">
          <sdPageHeader
            :title="
              accountData.attributes.tools && accountData.attributes.tools.length
                ? i18n.t('accounts.public.profit_and_tools')
                : i18n.t('accounts.public.profit')
            "
          />
        </a-col>
        <a-col :md="12" v-if="accountData.attributes.description" :sm="24">
          <sdCards headless>
            <div>
              <p class="account__description">{{ accountData.attributes.description }}</p>
              <p class="risk-level">
                {{ i18n.t('accounts.public.risk_level_title') }}
                <span :class="`risk-level ${accountData.attributes.risk_level}`">
                  {{ i18n.t('accounts.public.risk_level.' + accountData.attributes.risk_level) }}
                </span>
              </p>
            </div>
          </sdCards>
        </a-col>
        <a-col :md="12" v-if="!isPc">
          <sdPageHeader
            :title="
              accountData.attributes.tools && accountData.attributes.tools.length
                ? i18n.t('accounts.public.profit_and_tools')
                : i18n.t('accounts.public.profit')
            "
          />
        </a-col>
        <a-col :md="12" :sm="24">
          <sdCards headless>
            <div class="account-public__tools-profit df jcsb aic">
              <div class="accounts__profit-wrapper df jcsb aic profit">
                <div class="profit__inner">
                  <p class="profit__title">{{ i18n.t('accounts.public.profit_day') }}</p>
                  <p class="profit__text">{{ accountData.attributes.profit_day }}%</p>
                </div>
                <div class="profit__inner">
                  <p class="profit__title">{{ i18n.t('accounts.public.profit_month') }}</p>
                  <p class="profit__text">{{ accountData.attributes.profit_month }}%</p>
                </div>
                <div class="profit__inner">
                  <p class="profit__title">{{ i18n.t('accounts.public.profit_all') }}</p>
                  <p class="profit__text">{{ accountData.attributes.profit_all }}%</p>
                </div>
              </div>
              <div class="hr" v-if="accountData.attributes.tools && accountData.attributes.tools.length" />
              <div class="tools df aic" v-if="accountData.attributes.tools && accountData.attributes.tools.length">
                <Status
                  :text="tool"
                  :status="getColors(index)"
                  v-for="(tool, index) of accountData.attributes.tools"
                  :key="index"
                />
              </div>
            </div>
          </sdCards>
        </a-col>
        <a-col :md="12" :sm="24" v-if="isInvestor">
          <sdCards headless>
            <div class="account-public__tools-profit df jcsb aic">
              <div class="accounts__profit-wrapper df jcsb aic profit">
                <div class="profit__inner">
                  <p class="profit__title">{{ i18n.t('accounts.public.deposit') }}</p>
                  <p class="profit__text">{{ accountData.attributes.deposit }} USDT</p>
                </div>
                <div class="profit__inner">
                  <p class="profit__title">{{ i18n.t('accounts.public.withdrawal') }}</p>
                  <p class="profit__text">{{ accountData.attributes.withdrawal }} USDT</p>
                </div>
                <div class="profit__inner">
                  <p class="profit__title">{{ i18n.t('accounts.public.profit') }}</p>
                  <p class="profit__text">{{ accountData.attributes.profit }} USDT</p>
                </div>
              </div>
            </div>
          </sdCards>
        </a-col>
      </a-row>
      <a-tabs @change="changeTab" v-model:activeKey="activeKey" size="large" type="card">
        <a-tab-pane key="hour" :tab="i18n.t('accounts.public.profit_by_hour')">
          <sdCards headless>
            <TableWrapper class="table-data-view table-responsive" v-if="!tableLoading && profit?.columns">
              <a-table
                :pagination="{ pageSize: 20, showSizeChanger: false, hideOnSinglePage: true }"
                :dataSource="profit?.data"
                :columns="profit?.columns"
              />
            </TableWrapper>
            <a-skeleton active :paragraph="{ rows: 10 }" v-else />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane key="day" :tab="i18n.t('accounts.public.profit_by_day')">
          <sdCards headless>
            <TableWrapper class="table-data-view table-responsive" v-if="!tableLoading && profit?.columns">
              <a-table
                :pagination="{ pageSize: 10, showSizeChanger: true, hideOnSinglePage: true }"
                :dataSource="profit?.data ?? []"
                :columns="profit.columns"
              />
            </TableWrapper>
            <a-skeleton active :paragraph="{ rows: 10 }" v-else />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane key="month" :tab="i18n.t('accounts.public.profit_by_month')">
          <sdCards headless>
            <TableWrapper class="table-data-view table-responsive" v-if="!tableLoading && profit?.columns">
              <a-table
                :pagination="{ pageSize: 10, showSizeChanger: true, hideOnSinglePage: true }"
                :dataSource="profit?.data ?? []"
                :columns="profit.columns"
              />
            </TableWrapper>
            <a-skeleton active :paragraph="{ rows: 10 }" v-else />
          </sdCards>
        </a-tab-pane>
        <a-tab-pane key="transactions" :tab="i18n.t('transactions.title')" v-if="$route.params.type === 'invest'">
          <sdCards headless>
            <TableWrapper class="table-data-view table-responsive" v-if="transactions?.columns">
              <a-table :dataSource="transactions.data" :pagination="false" :columns="transactions.columns">
                <template #amount_operation="record">
                  <p>
                    {{ `${record.record.amount_operation} ${record.record.operation_currency}` }}
                  </p>
                </template>
              </a-table>
              <div style="text-align: right; margin-bottom: 20px">
                <a-pagination
                  :default-page-size="pageSize"
                  v-model:pageSize="pageSize"
                  :show-size-changer="true"
                  v-model:current="transactions.meta.current"
                  :total="transactions.meta.count"
                  @change="onChangeTransactions"
                  v-if="transactions.meta"
                >
                  <template #buildOptionText="props">
                    <span>{{ props.value }}/{{ i18n.t('start.page') }}</span>
                  </template>
                </a-pagination>
              </div>
            </TableWrapper>
            <a-skeleton active :paragraph="{ rows: 10 }" v-else />
          </sdCards>
        </a-tab-pane>
      </a-tabs>
    </div>
    <a-spin class="loading" size="large" v-else />
    <PublishModal v-model:data="publishModal" />
    <InvestPublicModal v-model:data="investModal" />
    <CloseInvestModal v-model:data="closeModal" />
  </Main>
</template>

<script>
import { Main, TableWrapper } from '../styled';
import { useI18n } from 'vue-i18n';
import { computed, defineAsyncComponent, ref, watchEffect, onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
const ModalCash = defineAsyncComponent(() => import('@/components/modals/ModalCash'));
const DepositCoinpayments = defineAsyncComponent(() => import('@/components/modals/overview/DepositCoinpayments'));
const TaskCard = defineAsyncComponent(() => import('@/components/elements/points/TaskCard'));
const PublicAccountButtons = defineAsyncComponent(() => import('@/components/elements/PublicAccountButtons'));
const Status = defineAsyncComponent(() => import('@/components/elements/Status.vue'));
const GraphComponent = defineAsyncComponent(() => import('@/components/elements/GraphComponent'));
const PublishModal = defineAsyncComponent(() => import('@/components/modals/PublishModal'));
const InvestPublicModal = defineAsyncComponent(() => import('@/components/modals/InvestPublicModal'));
const CloseInvestModal = defineAsyncComponent(() => import('@/components/modals/CloseInvestModal'));

import { publicTypes } from '@/config/helpers/JSONdata';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const Session = {
  name: 'Session',
  components: {
    Main,
    TableWrapper,
    ModalCash,
    DepositCoinpayments,
    TaskCard,
    PublicAccountButtons,
    Status,
    GraphComponent,
    PublishModal,
    InvestPublicModal,
    CloseInvestModal,
  },
  setup() {
    const { state, dispatch } = useStore();
    const route = useRoute();
    const i18n = useI18n();

    const linkValue = ref('id');
    const order = ref('desc');
    let pageSize = ref(10);
    let accId = ref(route.params.id);
    const activeKey = ref('hour');

    const publishModal = ref({ data: null, visible: false });
    const investModal = ref({ data: null, visible: false });
    const closeModal = ref({ data: null, visible: false });

    const accountData = computed(() => state.publicAccounts.account);
    const pageType = computed(() => publicTypes[route.params.type]);
    const isLoading = computed(() => state.publicAccounts.loading);
    const tableLoading = computed(() => state.publicAccounts.loadingTable);

    const isClosed = computed(() => ['closing', 'closed'].includes(accountData.value?.attributes.status));
    const isInvestor = computed(() => accountData.value?.attributes.kind === 'investor');
    const isPc = computed(() => window.innerWidth > 500);
    const windowWidth = computed(() => window.innerWidth);

    const getColors = (index) => {
      const colors = ['active', 'created', 'closed', 'leverage'];
      let i = index;
      if (colors.length < index) {
        do {
          i = i - colors.length;
        } while (colors.length < i);
      }
      return colors[i];
    };

    const changeTab = (action) => {
      if (action === 'transactions') {
        onChangeTransactions(1);
      } else {
        onChangeProfit(action);
      }
    };

    const onChangeProfit = (group_by) => {
      dispatch('getProfit', { id: accId.value, type: pageType.value, group_by });
    };

    const onChangeTransactions = (page) => {
      dispatch('getTransactions', {
        pageNum: page,
        pageSize: pageSize.value,
        sortableLink: '&sort_by=created_at&order=asc',
        accId: accId.value,
      });
    };

    const profit = computed(() => {
      const data = state.publicAccounts.profit.data.length
        ? JSON.parse(JSON.stringify(state.publicAccounts.profit.data)).map((el, index) => {
            el.key = index + 1;
            el.date = i18n.d(el.date, el.date.includes(':') ? 'long' : 'short');
            el.value += '%';
            el.total += '%';
            return el;
          })
        : [];
      const columns = [
        {
          title: i18n.t('accounts.public.number'),
          dataIndex: 'key',
          key: 'key',
        },
        {
          title: i18n.t('accounts.public.date'),
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: i18n.t('accounts.public.profit'),
          dataIndex: 'value',
          key: 'value',
        },
        {
          title: i18n.t('accounts.public.profit_total'),
          dataIndex: 'total',
          key: 'total',
        },
        {
          title: '',
          width: 0,
        },
      ];

      const meta = state.publicAccounts.profit.meta;

      return { data, columns, meta };
    });

    const openModal = (modalName, type) => {
      const data = {
        visible: true,
        data: {
          ...accountData.value.attributes,
          ...accountData.value.attributes.trade,
          id: accId.value,
        },
        type,
      };
      switch (modalName) {
        case 'publish':
          publishModal.value = { ...JSON.parse(JSON.stringify(data)) };
          return;
        case 'invest':
          investModal.value = { ...JSON.parse(JSON.stringify(data)) };
          return;
        case 'close':
          closeModal.value = { ...JSON.parse(JSON.stringify(data)) };
          return;
      }
    };

    const transactions = computed(() => {
      const data = state.accounts.transactionsData?.length
        ? state.accounts.transactionsData.map((transaction, key) => {
            const { attributes, id } = transaction;
            const {
              operable_type,
              amount_before,
              amount_after,
              amount_operation,
              operation_currency,
              created_at,
              status,
              operation_info,
              comment,
            } = attributes;
            return {
              key: key + 1,
              id,
              operable_type,
              balance: (
                <p style="display:flex;">
                  {' '}
                  {i18n.n(+amount_before, 'decimal')} {i18n.t('transactions.' + operation_currency)}{' '}
                  <span
                    style="margin:0 10px; white-space:nowrap;word-wrap:nowrap;"
                    class={+amount_after > +amount_before ? 'arrow-more' : 'arrow-less'}
                  >
                    ->
                  </span>{' '}
                  {i18n.n(+amount_after, 'decimal')} {i18n.t('transactions.' + operation_currency)}
                </p>
              ),
              operable_type_short: i18n.t('transactions.' + operable_type, 2),
              operation_currency: i18n.t('transactions.' + operation_currency),
              amount_operation: i18n.n(+amount_operation, 'decimal'),
              created_at: i18n.d(created_at, 'long'),
              status: i18n.t('transactions.' + status),
              normal_date: created_at,
              operation_info,
              comment,
            };
          })
        : [];

      const columns = [
        {
          title: i18n.t('transactions.created_at'),
          dataIndex: 'created_at',
          key: 'created_at',
        },
        {
          title: i18n.t('transactions.amount_operation'),
          dataIndex: 'amount_operation',
          key: 'amount_operation',
          slots: { customRender: 'amount_operation' },
        },
        {
          title: i18n.t('transactions.balance'),
          dataIndex: 'balance',
          key: 'balance',
        },
        {
          title: i18n.t('transactions.status'),
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: i18n.t('transactions.operable_type'),
          dataIndex: 'operable_type_short',
          key: 'operable_type_short',
        },
        {
          title: i18n.t('transactions.comment'),
          dataIndex: 'comment',
          key: 'comment',
        },
        {
          title: '',
          width: 0,
        },
      ];

      const meta = state.accounts.transactionsMeta ?? { current: 1, count: 0 };

      return { data, columns, meta };
    });

    watchEffect(async () => {
      if (route.params.id !== accId.value && route.params.type && route.params.id) {
        accId.value = route.params.id;
        onChangeProfit('day');
        await dispatch('getPublicAccount', { id: accId.value, type: pageType.value });
        dispatch('axiosTasksGetData');
        dispatch('getGraphPublicStats', { id: accId.value, type: pageType.value, col: 1 });
        dispatch('getProfit', { id: accId.value, type: pageType.value, group_by: 'day' });

        document.title =
          i18n.t(`accounts.${route.params.type}.page_title`, {
            name: accountData.value.attributes.title ?? accountData.value.attributes.trade.name,
          }) + ' | MTHUB';
      }
    });

    onMounted(async () => {
      if (accId.value) {
        await dispatch('getPublicAccount', { id: accId.value, type: pageType.value });
        dispatch('axiosTasksGetData');
        dispatch('getGraphPublicStats', { id: accId.value, type: pageType.value });
        dispatch('getProfit', { id: accId.value, type: pageType.value, group_by: 'day' });

        document.title =
          i18n.t(`accounts.${route.params.type}.page_title`, {
            name: accountData.value.attributes.title ?? accountData.value.attributes.trade.name,
          }) + ' | MTHUB';
      }
    });

    return {
      i18n,
      accountData,
      publishModal,
      investModal,
      closeModal,
      pageSize,
      faSort,
      faSortUp,
      faSortDown,
      order,
      linkValue,
      activeKey,
      isPc,
      isClosed,
      isInvestor,
      profit,
      transactions,
      isLoading,
      tableLoading,
      windowWidth,
      getColors,
      openModal,
      changeTab,
      onChangeTransactions,
    };
  },
};
export default Session;
</script>
<style scoped lang="scss">
.account__description {
  font-size: 18px;
  overflow-wrap: break-word;
}
.promo-info {
  display: flex;
  justify-content: flex-start;
  gap: 40px;
  width: 100%;

  p {
    margin: 0;
  }
  &__inner {
  }
  &__title {
    font-size: 18px;
  }
  &__text {
    font-size: 24px;
  }
}
.profit {
  justify-content: space-between;
  gap: 20px;
  width: 100%;

  p {
    margin: 0;
  }

  &__title {
    font-size: 18px;
  }
  &__text {
    font-size: 24px;
  }
}
.account__title {
  margin-bottom: 0;
  margin-right: 0;
}
p {
  margin-bottom: 0;
}
.ant-card {
  height: calc(100% - 25px);
}
.account-public {
  &__tools-profit {
    flex-direction: column;
  }
}
.tools {
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  .status {
    margin-left: 0 !important;
  }
}

.account__subtitle.title {
  margin-right: 0 !important;
  margin-left: 20px !important;
}

.df.aic.fww {
  flex-wrap: nowrap;
  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
  }
  justify-content: center;
}
</style>

<style lang="scss">
.account-public {
  .ant-col {
    width: 100%;
  }
  .ant-card-body {
    min-height: 100%;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    button {
      max-width: 150px;
    }
  }

  .risk-level {
    font-size: 18px;
    overflow-wrap: break-word;
    padding: 20px 0 0 0;
    &.low {
      color: #2c9aea;
    }

    &.medium {
      color: #5f63f2;
    }

    &.high {
      color: #f5222d;
    }
  }
}
</style>
